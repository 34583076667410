@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'mat-calendar';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
//@import '~ngx-toastr/toastr';
@import 'mat-tooltip';
@import 'mat-checkbox';
@import 'mat-radiobutton';
@import 'mat-form-field';
@import 'mat-list';
@import 'mat-stepper';
@import 'mat-paginator';
@import 'mat-select';
@import 'mat-table';
@import 'mat-dialog-content-v15';

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
p,
span,
h1,
h2,
h3,
h4,
h5,
strong,
.mat-tab-group {
  font-family: 'Ubuntu', sans-serif !important;
  color: #30373a;
}

::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4b5458;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: block !important;
  border-radius: 30px;
  background: #30373a;
}

.mat-paginator-page-size-select {
  width: 90px !important;
}

.indietro-button {
  width: 220px !important;
}

p.side_menu_p {
  cursor: pointer !important;
}

html {
  background-color: #f2f2f2;
}

body {
  height: 100vh;
  overflow-y: hidden;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.mat-step-header {
  pointer-events: none !important;
}

button {
  @apply font-ubuntu;
  text-transform: uppercase;
  font-size: 14px;

  font-weight: 500;
}

button span {
  font-family: 'ubuntu', sans-serif !important;
  font-weight: 500;
}

.mat-horizontal-stepper-header
  mat-step-header
  .ng-tns-c27-24
  .ng-star-inserted {
  pointer-events: none !important;
}

.container-fluid {
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.mat-select-value span {
  font-size: 15px;
}

.central-button {
  border-radius: 0 !important;
}

p {
  margin-bottom: 0;
}

button {
  outline: none !important;
}

.mat-tab-list {
  display: flex;
  justify-content: center;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent;
}

.mat-tab-labels > div {
  border: 2px solid #30373a;
  border-radius: 50px;
  margin-right: 10px;
  margin-left: 10px;
  height: 40px;
  width: 179px;
}

.mat-tab-label {
  background: #f2f2f2;
  color: #30373a;
  font-weight: 500;
  font-size: 14px;
  opacity: 1 !important;
  text-transform: uppercase;
}

.mat-tab-label:focus {
  background-color: #30373a !important;
}

.mat-tab-header {
  border-bottom: none;
}

.mat-tab-label:focus {
  @apply bg-redMovesion;
  color: white;
  opacity: 1 !important;
}

.mat-tab-label-active {
  background-color: #30373a;
  color: white;
  opacity: 1 !important;
}

.mat-button-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-dialog-container {
  background-color: transparent;
  padding: 0 !important;
  box-shadow: none;
  border-radius: 15px 15px 4px 4px;
}

// .mat-mdc-dialog-container {
//   background-color: transparent;
//   padding: 0 !important;
//   box-shadow: none;
//   border-radius: 15px 15px 4px 4px;

// }

.button_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.save_button.mat-raised-button {
  @apply bg-redMovesion;

  color: white;
  border-radius: 40px !important;
  height: 40px;
  width: 150px;
  padding: 0;
  line-height: 1;
}

.close_button.mat-raised-button {
  @apply text-redMovesion;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 10px;
  box-shadow: none;
  padding: 0;
  line-height: 1;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.no_padding {
  padding: 0;
}

.column {
  display: flex;
  flex-direction: column;
}

.mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
  transition: none;
}

h1,
h2,
h3,
h4,
h5,
span {
  @apply font-ubuntu;
}

.hide {
  display: none;
}

.mat-menu-item[_ngcontent-c2] {
  color: black !important;
}

.mat-step-header .mat-step-icon {
  background-color: transparent;
  border: 2px solid #30373a;
}

.mat-step-header .mat-step-icon span {
  color: #30373a;
}

.mat-step-icon-state-edit {
  background: #30373a !important;
  border: 2px solid #30373a !important;
}

.mat-step-icon-state-edit .mat-icon {
  color: white !important;
  height: 16px !important;
}

.mat-step-icon {
  @apply font-ubuntu;
  height: 50px !important;
  width: 50px !important;

  font-weight: 700;
  font-size: 22px;
}

.mat-horizontal-stepper-header .mat-step-icon {
  margin-right: 0;
}

.mat-horizontal-stepper-header {
  padding: 0 !important;
}

// .mat-dialog-container::-webkit-scrollbar {
//   display: none !important;
// }

.modal_container::-webkit-scrollbar {
  display: block !important;
}

.modal_container {
  overflow: scroll !important;
}

.mat-paginator {
  background: none !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-paginator-container {
  justify-content: center !important;
  margin-top: 30px;
}

.mat-expansion-indicator::after {
  color: white;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel {
  border-radius: 0 !important;
}

.step_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.step_button button {
  width: 350px !important;
}

.mat-expansion-panel-header span {
  font-size: 16px;
}

.outline_button a {
  @apply text-redMovesion;
}

.outline_button a:hover,
.outline_button a:focus,
.outline_button a:active,
.outline_button a:visited {
  @apply text-redMovesion;
  text-decoration: none;
}

.mat-tab-body-content {
  height: auto !important;
}

.disabled_profile {
  opacity: 0.5;
  pointer-events: none;
}

.mat-expansion-panel-header-title {
  font-size: 13px !important;
  text-transform: uppercase;
}

.mat-button-wrapper {
  color: white !important;
}

.col-12.flex-start .mat-raised-button .mat-button-wrapper {
  width: 100%;
}

.mat-raised-button {
  box-shadow: none !important;
}

.clear-mov {
  text-transform: initial;
  background: none;
  box-shadow: none;
  color: white;
  font-weight: 400 !important;
  width: auto !important;
}

.clear-mov span {
  font-weight: 400 !important;
  font-size: 17px;
}

.clear-mov strong {
  color: white !important;
}

.clear-mov i {
  margin-right: 20px;
}

.fixed-top {
  z-index: 900;
}

.barra-fixed .row {
  width: 100%;
}

.date_range .mat-button-wrapper {
  color: #30373a !important;
  text-transform: initial;
}

.mezzi_container .mat-select-trigger,
#percorso_container .mat-select-trigger,
.stepper_container .mat-select-trigger {
  height: auto !important;
}

.mezzi_container .mat-select-arrow {
  margin-top: 8px !important;
}

.statistiche_button,
.menu-button {
  text-transform: initial;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statistiche_button .mat-button-wrapper {
  color: #30373a !important;
}

.statistiche_button .mat-icon,
.menu-button .mat-icon {
  height: 24px !important;
  margin-right: 5px;
}

.menu-button .mat-button-wrapper {
  color: #30373a !important;
}

.done_container {
  background: #39b54a !important;
}

.mat-mini-fab {
  box-shadow: none !important;
}

.richieste .mat-tab-label[aria-posinset='2'].mat-tab-label-active {
  @apply bg-redMovesion border border-solid border-redMovesion;
}

.mat-step-label {
  display: none !important;
}

.mat-horizontal-stepper-header .mat-step-icon {
  margin-right: 0 !important;
}

.mat-stepper-horizontal-line {
  margin: 0 30px !important;
}

.mat-horizontal-stepper-header[ng-reflect-active='true'] span {
  color: white !important;
}

.mat-horizontal-stepper-header[ng-reflect-active='false'] .mat-step-icon,
.mat-horizontal-stepper-header[ng-reflect-selected='false'] .mat-step-icon {
  background: transparent !important;
  border: 2px solid #30373a;
}

.mat-horizontal-stepper-header[ng-reflect-active='false'] span,
.mat-horizontal-stepper-header[ng-reflect-selected='false']
  .mat-step-icon
  span {
  color: #30373a !important;
}

.mat-horizontal-stepper-header-container {
  padding: 0 100px;
}

.app-price .mat-button-wrapper {
  color: #999999 !important;
}

.button_riepilogo .bozze .mat-button-wrapper {
  color: #30373a !important;
}

.modal_exit {
  border-radius: 15px 15px 4px 4px;
  padding: 80px 15px 30px 15px !important;
}

.chiudi-premio .mat-button-wrapper {
  color: #30373a !important;
  //line-height: 1;
  //display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
  line-height: 1 !important;
}

.chiudi-premio .mat-icon {
  width: 16px;
  height: 16px !important;
  font-size: 16px;
}

.modal_exit p {
  font-size: 17px !important;
  font-weight: 500 !important;
  padding: 10px 25px 15px 25px;
}

#modal_white #circle {
  width: 110px !important;
  height: 110px !important;
}
#modal_white #circle .mat-icon {
  font-size: 70px;
  width: 70px;
  height: 70px !important;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

#no_button,
#yes_button {
  width: 110px;
  height: 35px !important;
  line-height: 1 !important;
  padding: 0 !important;
  text-transform: uppercase !important;
  border: none !important;
}

.modal_exit .border-top {
  border-top: 1px solid #999999;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

#no_button {
  background: #30373a !important;
}

#yes_button {
  @apply bg-redMovesion;
}

.mat-datepicker-toggle-default-icon {
  @apply text-redMovesion;
}

.mat-horizontal-stepper-header[ng-reflect-active='false']
  .mat-step-icon.mat-step-icon-state-edit,
.mat-horizontal-stepper-header[ng-reflect-selected='false']
  .mat-step-icon.mat-step-icon-state-edit {
  background: #30373a !important;
}

.modal_exit .datepickers {
  padding: 0;
}

.clear-mov .mat-button-wrapper {
  display: flex;
  align-items: center;
}

.mat-menu-panel .mat-select-placeholder {
  color: black !important;
  text-transform: uppercase !important;
}

.punti_button .mat-icon {
  height: 25px !important;
}

.mat-menu-content:not(:empty) {
  padding-top: 20px !important;
  padding-bottom: 60px !important;
}

.mat-calendar-period-button .mat-button-wrapper {
  color: black !important;
}

.filtro-dropdown .mat-menu-content:not(:empty) {
  padding-bottom: 20px !important;
}

.carousel-control-prev-icon {
  background-image: url('assets/img/prev_1.png');
}

.carousel-control-next-icon {
  background-image: url('assets/img/next_3.png');
}

@media only screen and (max-width: 1550px) {
  .login-card {
    margin-bottom: 80px;
    margin-top: 30px;
  }
  .barra-fixed {
    height: 50px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .mat-horizontal-stepper-header-container {
    padding: 30px 100px;
  }
}

@media only screen and (max-width: 991px) {
  .mat-horizontal-stepper-header-container {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .mat-step-icon {
    height: 30px !important;
    width: 30px !important;
    font-size: 15px;
  }

  .mat-stepper-horizontal-line {
    margin: 0 10px !important;
  }

  .collapse.show {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .mat-horizontal-content-container {
    padding: 0 !important;
  }
  .mat-raised-button.clear-mov {
    height: 40px;
    line-height: 1px;
  }
  .clear-mov span {
    font-size: 13px;
  }
  .barra-fixed .row {
    flex-wrap: nowrap;
  }
  .mat-horizontal-stepper-header-container {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 450px) {
  .mat-stepper-horizontal-line {
    margin: 0 10px !important;
  }

  #no_button {
    margin-left: 0 !important;
    margin-right: 5px !important;
    width: 120px !important;
  }

  #yes_button {
    margin-right: 0 !important;
    margin-left: 5px !important;
    width: 120px !important;
  }

  .modal_exit p {
    padding: 20px 15px;
  }

  .mat-tab-labels > div {
    padding: 0;
    width: 120px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .mat-stepper-horizontal-line {
    min-width: 15px !important;
    margin: 0 5px !important;
  }
}

@media only screen and (max-width: 350px) {
  .button_stepper div:first-child {
    padding-right: 3px;
  }

  .button_stepper div:last-child {
    padding-left: 3px;
  }

  mat-horizontal-stepper form {
    padding: 0 5px;
  }
}

/* Absolute Center Spinner */

#load_spinner {
  position: fixed;
  z-index: 999;
  height: 1px;
  width: 1px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */

#load_spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(107, 107, 107, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */

#load_spinner:not(:required) {
  /* hide #load_spinner..." text */
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

#load_spinner svg {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

#ball {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  -webkit-animation: spinner 1000ms infinite linear;
  -moz-animation: spinner 1000ms infinite linear;
  -ms-animation: spinner 1000ms infinite linear;
  -o-animation: spinner 1000ms infinite linear;
  animation: spinner 1000ms infinite linear;
}

#load_spinner:not(:required):after {
  content: ' ';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 2000ms infinite linear;
  -moz-animation: spinner 2000ms infinite linear;
  -ms-animation: spinner 2000ms infinite linear;
  -o-animation: spinner 2000ms infinite linear;
  animation: spinner 2000ms infinite linear;
  border-radius: 0.5em;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

agm-map .gm-svpc {
  display: none !important;
}

.border-end {
  border-right: 1px solid #cccccc !important;
}
.mat-mdc-progress-spinner {
  //--mdc-circular-progress-active-indicator-color: #3f51b5;
  --mdc-circular-progress-active-indicator-color: #999999;
}

.loginSpinner.mat-mdc-progress-spinner
  .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: white;
}

.policyCheckbox.mat-checkbox.mat-checkbox-checked
  .mat-checkbox-inner-container
  .mat-checkbox-background {
  border-color: #30373a;
  background-color: #30373a;
}

.policyCheckbox.mat-checkbox .mat-checkbox-frame {
  border-color: rgb(0 0 0 / 54%);
}

.policyCheckbox.mat-checkbox.mat-checkbox-checked
  .mat-checkbox-inner-container
  .mat-checkbox-background
  .mat-checkbox-checkmark-path {
  filter: none !important;
  stroke: white !important;
}
