@import 'src/variables';

.mat-list-item {
  height: 50px !important;
}

.mat-list {
  &.mat-list-base {
    padding-top: 0;

    height: 100%;
    min-height: calc(90vh - 98px);
    overflow: hidden;

    .mat-list-item {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;

      &:first-child {
        border-top: 1px solid white;
      }

      border-bottom: 1px solid white;


      .mat-list-item-content {
        margin: 0;

        width: 100%;
        display: flex;
        align-items: center;

        mat-icon {
          padding: 0;
          margin: 0;
        }

        @media (max-width: 1024px) {
          padding: 0;
          justify-content: center;
        }

      }

    }
  }
}
