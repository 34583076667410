.mat-calendar {
  @apply font-ubuntu;
  display: block;
  width: 296px;
  height: 354px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;

  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border-radius: 15px 15px 2px 2px;

  .mat-calendar-header {
    background-color: #d9d9d9;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    .mat-calendar-controls {
      margin-top: 0;
      // margin-bottom: 0;
    }
  }

  .mat-calendar-body-cell.mat-calendar-body-active {
    .mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-today {
      background-color: #30373a;
      color: white;
    }
  }
}
