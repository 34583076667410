.mat-checkbox {
  &.withWrap {
    label {
      white-space: normal;
    }
  }

  &.normal {
    background-color: transparent;
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: flex-start;
      .mat-checkbox-label {
        color: #30373a;
        text-align: left;
        font-weight: normal;
        line-height: 1.5;
        padding-right: 30px !important;
      }
    }

    .mat-checkbox-inner-container {
      margin:0;
      width: 35px;
      height: 35px;
      .mat-checkbox-frame {
        background-color: white;
        border: none;
      }
    }
  }

  &.allSelected {
    min-height: 30px;
    display: flex;
    .mat-checkbox-layout {
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      min-height: 30px;


      .mat-checkbox-inner-container {
        width: 25px;
        height: 25px;
        margin-left: 5px;

        .mat-checkbox-frame,
        .mat-checkbox-background {
          width: 100%;
          height: 100%;
        }
      }
      .mat-checkbox-label {
        justify-self: center;
        margin-right: auto;
        margin-left: auto;

      }
    }
    &.mat-checkbox-checked {
        background: #30373a;
      color: white;
      .mat-checkbox-inner-container {
        margin-left: 5px;
      }
      .mat-checkbox-label {
        color: white;
        font-weight: 900;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}

// .mat-checkbox-inner-container {
//     margin: 0 !important;
//     margin-right: 10px !important;
// }

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-thumb {
      @apply bg-redMovesion;
    }

    .mat-slide-toggle-bar {
      background-color: rgba(198, 28, 13, 0.54);
    }
  }
}

.pooling_container .mat-checkbox-frame,
.pooling_container .mat-checkbox-background {
  border-radius: 50% !important;
  width: 20px;
  height: 20px;
}

.stepper_card .mat-checkbox-frame,
.stepper_card .mat-checkbox-background {
  border-radius: 50% !important;
  width: 20px;
  height: 20px;
}

.stepper_card {
  .card {
    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
    }
  }
}

.col-25 {
  .mat-checkbox-frame {
    border-radius: 50%;
    border-color: black;
    background-color: white;
  }

  .mat-checkbox-inner-container {
    width: 50px;
    height: 50px;
  }

  .mat-checkbox-background {
    border-radius: 50%;
  }

  .mat-checkbox-checked {
    background: #f2f2f2;

    .mat-checkbox-checkmark {
      opacity: 0;
    }

    .mat-checkbox-label {
      color: white;
      background: #30373a;
      border-radius: 50%;
    }
  }

  .mat-checkbox {
    position: relative;
    width: 50px;
    height: 50px;
  }

  .mat-checkbox-label {
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    padding-top: 18px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    color: #30373a;
    z-index: 10;
    background: #f2f2f2;
    border-radius: 50%;
    border: 2px solid #30373a;
  }
}

.mat-checkbox {
  text-align: center;
  border-radius: 15px;
  background: #f2f2f2;
//   height: 30px;
  display: flex;
  justify-content: center;

  .mat-accent {
    .mat-checkbox-ripple {
      .mat-ripple-element {
        background-color: white !important;
      }
    }
  }

  &:not(.mat-checkbox-disabled).mat-accent {
    .mat-checkbox-ripple {
      .mat-ripple-element {
        background: white;
      }
    }
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: white;
}

.card {
  .mat-checkbox-background {
    border-radius: 50%;
  }

  .mat-checkbox-frame {
    border-radius: 50%;
  }

  .mat-checkbox-inner-container {
    width: 40px;
    height: 40px;
  }
}

.mat-checkbox-frame,
.mat-checkbox-background {
  border-radius: 50% !important;
}

.pooling_container {
  .mat-checkbox {
    width: 150px !important;
  }
}

.mat-checkbox-layout {
  vertical-align: middle;
  margin-bottom: 4px;
  margin-top: 4px;

  .mat-checkbox-label {
    font-size: 13px;
    font-weight: 900;
  }
}

.mat-checkbox-label {
  margin-left: 10px;
}

.mat-checkbox-checkmark-path {
  stroke: #30373a !important;
}

.stepper_container {
  .week {
    .mat-checkbox-layout {
      width: 50px;
      height: 50px;
    }
  }
}

.select-ride {
  .mat-checkbox-layout {
    align-items: center !important;
  }
}

.select-ride .mat-checkbox,
.card_passeggeri .mat-checkbox {
  width: 165px;
  height: 30px;
}

.select-ride .mat-checkbox-inner-container,
.card_passeggeri .mat-checkbox-inner-container {
  width: 16px;
  height: 16px;
}

.select-ride .mat-checkbox-label,
.card_passeggeri .mat-checkbox-label {
  text-transform: uppercase;
  font-size: 14px;
}

.insurance .mat-checkbox-checked .mat-checkbox-label,
.insurance .mat-checkbox-label {
  color: #30373a !important;
  text-align: left;
  line-height: 17px;
}

.insurance {
  .mat-checkbox-layout {
    white-space: initial;
  }

  .mat-checkbox-inner-container {
    top: 5px;
    width: 18px;
    height: 18px;
    margin-right: 0 !important;
  }

  .mat-checkbox-checkmark-path {
    stroke: white !important;
  }
}

.insurance .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.insurance .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background: #b01352 !important;
}

@media only screen and (max-width: 991px) {
  .mat-horizontal-stepper-header-container .card .mat-checkbox-inner-container {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .col-25 .mat-checkbox-label {
    font-size: 14px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .pooling_container .mat-checkbox {
    width: 135px !important;
  }

  .mat-checkbox-label {
    margin-left: 5px;
  }
}
