
.cerca-passeggero .mat-radio-checked {
  position: relative;
}

.cerca-passeggero .mat-radio-checked .mat-radio-label-content {
  display:none !important;
}

.cerca-passeggero .mat-radio-checked .mat-radio-label::after {
  position:absolute;
  content:"SELEZIONATO";
  color: white;
  font-size: 13px;
  top:50%;
  right:50%;
  transform: translate(50%,-50%);
}


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  @apply border-redMovesion;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
@apply bg-redMovesion;
}


#profilo_tabs_container .mat-radio-button {
  width: 100px;
}



.mat-radio-container {
  display: none !important;
}

.mat-radio-button {
  width: 100%;
  color: #30373A;
  background: #F2F2F2;
  border-radius: 15px;
}

.mat-radio-label {
  width: 100%;
  color: #30373A;
  font-size: 13px;
  font-weight: 900;
  justify-content: center;
  margin-bottom: 0;
  height: 30px;
}

.mat-radio-button.mat-radio-checked {
  width: 100%;
  background: #30373A;
  border-radius: 15px;
  color: white;
}
.mat-radio-button.mat-radio-checked .mat-radio-label,
.mat-radio-checked .mat-radio-label {
  color: white;
  > span{
    color: white;
  }
}



.mat-radio-label-content {
  padding-left: 0 !important;
}


.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #30373a9d;
}


#anagrafica_container .mat-radio-button {
  margin-right: 30px;
}


.pooling_container .mat-radio-button {
  width: 150px !important;
}




.stepper_container .radio_container .mat-radio-group {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.stepper_container .radio_container .mat-radio-button {
  background: transparent !important;
  border: 2px solid #30373A;
  width: 220px;
  height: 40px !important;
  border-radius: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepper_container .radio_container .mat-radio-checked {
  @apply bg-redMovesion border-2 border-solid border-redMovesion;

}

.stepper_container .radio_container .mat-radio-button:first-child {
  margin-right: 10px;
}

.stepper_container .radio_container .mat-radio-button:last-child {
  margin-left: 10px;
}

.stepper_container .week .mat-radio-label-content {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}



.stepper_container .radio_container .mat-radio-button {
  text-transform: uppercase;
}


@media only screen and (max-width: 1200px) {
  #anagrafica_container .mat-radio-button {
    margin-right: 10px;
  }
}


@media only screen and (max-width: 450px) {
  .stepper_container .radio_container .mat-radio-button .mat-radio-label {
    font-size: 11px !important;
  }
}



@media only screen and (max-width: 400px) {

  .pooling_container .mat-radio-button {
    width: 135px !important;
  }
}
