$mat-icon-width: 20px;
$border-radius: 24px;
$min-height: 36px; //48px
$label-color: #999999;
$label-font-size: 12px;
$prefix-suffix-color: #404040;
$greyBg: rgba(255, 255, 255, 0.4);
$greyFg: white;

.mat-form-field{

  &.historySelect{
    >.mat-form-field-wrapper{
      background-color: #999999!important;
    }
  }
&.transportSelect{
  >.mat-form-field-wrapper{
    background-color: #30373A !important;
  }
}

  &.number {
    max-width: 150px !important;
  }
  &.mat-form-field.mat-form-field-appearance-fill {
    width: 100%;


    .mat-datepicker-input {
      color: $prefix-suffix-color;
    }

    /* Disabled */
    &.mat-form-field-disabled {
      opacity: 0.7 !important;
    }

    /* Invalid */
    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        /* Border color */
        .mat-form-field-flex {
          // @apply border-warn dark:border-warn #{'!important'};
          --tw-border-opacity: 1 !important;
          border-color: rgba(
            var(--mobilitycompany-warn-rgb),
            var(--tw-border-opacity)
          ) !important;
        }
      }
    }

    /* Focused */
    &.mat-focused {
      .mat-form-field-wrapper {
        /* Background color */
        .mat-form-field-flex {
          // @apply bg-card dark:bg-card #{'!important'};
          --tw-bg-opacity: 1 !important;
          background-color: rgba(
            var(--mobilitycompany-bg-card-rgb),
            var(--tw-bg-opacity)
          ) !important;
        }
      }
    }

    /* Focused and valid fields */
    &.mat-focused:not(.mat-form-field-invalid) {
      .mat-form-field-wrapper {
        /* Border color */
        .mat-form-field-flex {
          // @apply border-primary dark:border-primary #{'!important'};
          --tw-border-opacity: 1 !important;
          border-color: rgba(
            var(--mobilitycompany-primary-rgb),
            var(--tw-border-opacity)
          ) !important;
        }
      }
    }

    /* Disable floating mat-label */
    &.mat-form-field-has-label.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          width: 100% !important;
          transform: none !important;
        }
      }
    }

    /* Remove the default arrow for native select */
    &.mat-form-field-type-mat-native-select {
      .mat-form-field-infix {
        select {
          top: auto;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-right: 18px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748B' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right -7px center;
          background-size: 24px;

          .dark & {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2397a6ba' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
          }
        }

        &:after {
          display: none;
        }
      }
    }

    /* Adjustments for mat-label */
    &.mat-form-field-has-label {
      .mat-form-field-wrapper {
        margin-top: 24px;
      }
    }

    /* Default style tweaks and enhancements */
    .mat-form-field-wrapper {
      margin-bottom: 16px;
      padding-bottom: 0;
      background-color: white;
      border-radius: 50px;

      .mat-form-field-flex {
        --tw-bg-opacity: 1 !important;
        background-color: rgba(
          var(--mobilitycompany-bg-card-rgb),
          var(--tw-bg-opacity)
        ) !important;

        position: relative;
        display: flex;
        align-items: stretch;
        min-height: $min-height;
        border-radius: $border-radius;
        padding: 0 16px;
        border-width: 1px;
        // @apply shadow-sm bg-white border-gray-300 dark:bg-black dark:bg-opacity-5 dark:border-gray-500 #{'!important'};
        --tw-border-opacity: 1 !important;
        border-color: rgba(203, 213, 225, var(--tw-border-opacity)) !important;

        .mat-form-field-prefix {
          > .mat-icon {
            margin-right: 12px;
          }

          > .mat-icon-button {
            margin: 0 4px 0 -10px;
          }

          > .mat-select {
            margin-right: 10px;
          }

          > .mat-datepicker-toggle {
            margin-left: -15px;
          }

          > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
            margin-right: 12px;
          }
        }

        .mat-form-field-suffix {
          > .mat-icon {
            margin-left: 12px;
          }

          > .mat-icon-button {
            margin: 0 -10px 0 4px;
          }

          > .mat-select {
            margin-left: 10px;
          }

          > .mat-datepicker-toggle {
            margin-right: -8px;
          }
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          // @apply text-hint #{'!important'};
          // --tw-text-opacity: 1;
          // color: rgba(var(--mobilitycompany-text-hint-rgb), var(--tw-text-opacity));
          color: $prefix-suffix-color;

          .mat-icon {
            width: 20px;
            height: 20px;
            font-size: 19px;
          }

          .mat-icon-button {
            width: 40px;
            min-width: 40px;
            height: 40px;
            min-height: 40px;
          }

          .mat-icon,
          .mat-icon-button:not(.mat-button-disabled),
          .mat-select-value {
            // @apply text-hint;
            // --tw-text-opacity:1;
            // color:rgba(var(--mobilitycompany-text-hint-rgb), var(--tw-text-opacity));
            color: $prefix-suffix-color;
          }

          /* Remove the margins from the mat-icon if it's inside a button */
          /* Force the icon size to 24 */
          .mat-button,
          .mat-raised-button,
          .mat-icon-button,
          .mat-stroked-button,
          .mat-flat-button,
          .mat-fab,
          .mat-mini-fab {
            .mat-icon {
              margin: 0 !important;
              // @apply icon-size-6;
              width: 1.5rem;
              height: 1.5rem;
              min-width: 1.5rem;
              min-height: 1.5rem;
              font-size: 1.5rem;
              line-height: 1.5rem;
            }
          }

          /* Datepicker default icon size */
          .mat-datepicker-toggle-default-icon {
            // @apply icon-size-6;
            width: 1.5rem;
            height: 1.5rem;
            min-width: 1.5rem;
            min-height: 1.5rem;
            font-size: 1.5rem;
            line-height: 1.5rem;
          }

          /* Make mat-select usable as prefix and suffix */
          .mat-select {
            display: flex;
            align-items: center;

            &:focus {
              .mat-select-trigger {
                .mat-select-value {
                  // @apply text-primary #{'!important'};
                  --tw-text-opacity: 1 !important;
                  color: rgba(
                    var(--mobilitycompany-primary-rgb),
                    var(--tw-text-opacity)
                  ) !important;
                }

                .mat-select-arrow-wrapper {
                  .mat-select-arrow {
                    border-top-color: var(--mobilitycompany-primary) !important;
                  }
                }
              }
            }

            .mat-select-trigger {
              display: flex;
              align-items: center;

              .mat-select-value {
                display: flex;
                max-width: none;

                mat-select-trigger {
                  .mat-icon {
                    margin: 0 !important;
                  }
                }
              }

              .mat-select-arrow-wrapper {
                display: flex;
                align-items: center;
                transform: none;
                margin-left: 4px;

                .mat-select-arrow {
                  min-height: 0;
                  // @apply text-gray-500 dark:text-gray-400 #{'!important'};
                  --tw-text-opacity: 1 !important;
                  color: rgba(100, 116, 139, var(--tw-text-opacity)) !important;
                }
              }
            }
          }
        }

        .mat-form-field-infix {
          position: static;
          display: flex;
          align-items: center;
          width: 88px;
          padding: 0;
          border: 0;
          font-weight: normal;
          font-size: 15px;
          letter-spacing: 0.38px;
          font-family: Ubuntu, sans-serif;

          .mat-input-element {
            padding: 4px 0; /*padding: 14px 0;*/
            margin-top: 0;
          }

          /* Textarea */
          textarea.mat-input-element {
            display: flex;
            align-self: stretch;
            min-height: 36px;
            height: auto;
            margin: 14px 0;
            padding: 0 6px 0 0;
            transform: none;
          }

          /* Select */
          .mat-select {
            display: inline-flex;

            .mat-select-trigger {
              display: inline-flex;
              align-items: center;
              width: 100%;

              .mat-select-value {
                font-weight: 400;
                font-size: 19px;
                padding-left: 0;
                display: flex;
                position: relative;
                max-width: none;

                .mat-select-value-text {
                  display: inline-flex;

                  > * {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
              }
            }

            .mat-select-arrow-wrapper {
              transform: translateY(0);

              .mat-select-arrow {
                margin: 0 0 0 8px;
              }
            }
          }

          /* Chips */
          .mat-chip-list {
            width: 100%;
            margin: 0 -8px;

            .mat-chip-input {
              margin: 0 0 0 8px;
            }
          }

          .mat-form-field-label-wrapper {
            top: -20px;
            height: auto;
            padding-top: 0;
            overflow: visible;
            pointer-events: auto;
            margin-left: 16px; // 16 px padding orizzontale dato dall'infix

            .mat-form-field-label {
              position: relative;
              top: 0;
              margin-top: 0;
              backface-visibility: hidden;
              transition: none;
              font-weight: 500;
              text-transform: uppercase;
              // @apply text-default #{'!important'};
              --tw-text-opacity: 1 !important;
              color: $label-color; //rgba(var(--mobilitycompany-text-default-rgb), var(--tw-text-opacity)) !important;
              font-size: $label-font-size;
              & > mat-label {
                color: $label-color; //rgba(var(--mobilitycompany-text-default-rgb), var(--tw-text-opacity)) !important;
                font-size: $label-font-size !important;
                font-weight: 500;
              }
            }
          }
        }

        .mat-form-field-prefix {
          & ~ .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              margin-left: calc(
                16px + 32px
              ); // 16 px padding orizzontale infix, 32px la larghezza icona con anche il margine
            }
          }
        }
      }

      /* Remove the underline */
      .mat-form-field-underline {
        display: none;
      }

      /* Subscript tweaks */
      .mat-form-field-subscript-wrapper {
        position: relative;
        top: auto;
        padding: 0;
        margin-top: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;

        > div {
          display: contents; /* Remove the div from flow to stop the subscript animation */
        }

        .mat-error,
        .mat-hint {
          display: block;
          margin-top: 4px;
        }

        .mat-hint {
          // @apply text-hint #{'!important'};
          --tw-text-opacity: 1 !important;
          color: rgba(
            var(--mobilitycompany-text-hint-rgb),
            var(--tw-text-opacity)
          ) !important;
        }
      }
    }

    /* Adds better alignment for textarea inputs */
    &.mat-textarea {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-prefix,
          .mat-form-field-suffix {
            align-items: flex-start;
          }

          .mat-form-field-prefix {
            padding-top: 12px;
          }

          .mat-form-field-suffix {
            padding-top: 12px;
          }
        }
      }
    }

    /* Removes subscript space */
    &.mat-no-subscript {
      .mat-form-field-wrapper {
        padding-bottom: 0;
        margin-bottom: 0;

        .mat-form-field-subscript-wrapper {
          display: none !important;
          height: 0 !important;
        }
      }
    }

    /* Rounded */
    &.mat-rounded {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-radius: 24px;
        }
      }

      /* Emphasized affix */
      &.mobilitycompany-mat-emphasized-affix {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-prefix {
              border-radius: 24px 0 0 24px;

              > .mat-icon {
                margin-right: 12px;
              }

              > .mat-icon-button {
                margin-right: 2px;
              }

              > .mat-select {
                margin-right: 8px;
              }

              > .mat-datepicker-toggle {
                margin-right: 4px;
              }

              > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
                margin-right: 12px;
              }
            }

            .mat-form-field-suffix {
              border-radius: 0 24px 24px 0;

              > .mat-icon {
                margin-left: 12px !important;
              }

              > .mat-icon-button {
                margin-left: 2px !important;
              }

              > .mat-select {
                margin-left: 12px !important;
              }

              > .mat-datepicker-toggle {
                margin-left: 4px !important;
              }

              > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
                margin-left: 12px !important;
              }
            }
          }
        }
      }
    }

    /* Dense */
    &.mat-dense {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          min-height: 40px;

          .mat-form-field-prefix,
          .mat-form-field-suffix {
            .mat-icon-button {
              width: 32px;
              min-width: 32px;
              height: 32px;
              min-height: 32px;
            }
          }

          .mat-form-field-prefix {
            > .mat-icon-button {
              margin-left: -6px;
              margin-right: 12px;
            }
          }

          .mat-form-field-suffix {
            > .mat-icon-button {
              margin-left: 12px;
              margin-right: -6px;
            }
          }

          .mat-form-field-infix {
            .mat-input-element {
              padding: 11px 0;
            }
          }
        }
      }

      /* Rounded */
      &.mobilitycompany-mat-rounded {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            border-radius: 20px;
          }
        }

        /* Emphasized affix */
        &.mobilitycompany-mat-emphasized-affix {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              .mat-form-field-prefix {
                border-radius: 20px 0 0 20px !important;
              }

              .mat-form-field-suffix {
                border-radius: 0 20px 20px 0 !important;
              }
            }
          }
        }
      }
    }

    /* Emphasized affix */
    &.mat-emphasized-affix {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-prefix {
            margin: 0 16px 0 -16px;
            padding-left: 16px;
            border-radius: 6px 0 0 6px;
            border-right-width: 1px;

            > .mat-icon {
              margin-right: 16px;
            }

            > .mat-icon-button {
              margin: 0 6px 0 -10px;
            }

            > .mat-select {
              margin-right: 12px;
            }

            > .mat-datepicker-toggle {
              margin-right: 8px;
            }

            > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
              margin-right: 16px;
            }
          }

          .mat-form-field-suffix {
            margin: 0 -16px 0 16px;
            padding-right: 16px;
            border-radius: 0 6px 6px 0;
            border-left-width: 1px;

            > .mat-icon {
              margin-left: 16px;
            }

            > .mat-icon-button {
              margin: 0 -10px 0 6px;
            }

            > .mat-select {
              margin: 0 -4px 0 16px;
            }

            > .mat-datepicker-toggle {
              margin-left: 8px;
            }

            > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
              margin-left: 16px;
            }
          }

          .mat-form-field-prefix,
          .mat-form-field-suffix {
            // @apply bg-default border-gray-300 dark:border-gray-500 #{'!important'};
            --tw-bg-opacity: 1 !important;
            background-color: rgba(
              var(--mobilitycompany-bg-default-rgb),
              var(--tw-bg-opacity)
            ) !important;
            --tw-border-opacity: 1 !important;
            border-color: rgba(
              203,
              213,
              225,
              var(--tw-border-opacity)
            ) !important;
          }
        }
      }
    }

    /* Bolder border width */
    &.mat-bold {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          border-width: 2px !important;
        }
      }
    }

    &.no-help{
        .mat-form-field-wrapper{
            margin-bottom: 0 !important;
        }
    }
  }

  &.greybg {
    &.mat-form-field-appearance-fill {
      &.mat-focused {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            background-color: $greyBg !important;
          }
        }
      }
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          background-color: $greyBg !important;
          color: $greyFg;
          .mat-icon {
            color: $greyFg !important;
          }

          .mat-input-element {
            &::placeholder {
              color: darken($greyFg, 20) !important;
            }
          }
        }
      }
      mat-select {
        //background-color: $greyBg;
        .mat-select-value-text,
        .mat-select-arrow {
          color: $greyFg;
        }
      }
    }
  }
}

// .login-container .mat-form-field-prefix .mat-icon, .login-container .mat-form-field-suffix .mat-icon {
//   font-size: 20px !important;
// }


// .mat-form-field-flex {
//   border-radius: 100px;
//   background-color: white;
//   // box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
// }


// .login-container .mat-form-field-wrapper, .login-container .mat-form-field-flex, .login_form_input {
//   width: 280px;
//   height: 30px;
//   margin-bottom: 30px;
// }

// .login-container .mat-form-field-wrapper:last-child, .login-container .mat-form-field-flex:last-child, .login_form_input:last-child {
//   margin-bottom: 15px;
// }

// .mat-form-field-underline .ng-tns-c2-0 .ng-star-inserted {
//   height: 0 !important;
// }

// .mat-form-field-appearance-legacy .mat-form-field-underline {
//   height: 0 !important;
// }

// .mat-form-field-label-wrapper {
//   padding-top: 1.2em;
//   margin-left: 10px;
// }

// .mat-form-field-infix {
//   border-top: 0;
// }

// .mat-form-field-appearance-legacy .mat-form-field-ripple {
//   top: 19px !important;
// }

// .mat-form-field-prefix, .mat-icon {
//   height: 20px !important;
// }


// .mat-form-field-label-wrapper label {
//   margin-left: 10px !important;
// }

// .mat-form-field-appearance-legacy .mat-form-field-infix {
//   padding-left: 20px;
//   padding-right: 20px;
// }

// .mat-form-field.mat-focused .mat-form-field-ripple {
//   background-color: #B01352;
// }

// .mat-form-field-ripple {
//   left: 6% !important;
//   width: 89% !important;
// }

// .mat-form-field.mat-focused .mat-form-field-label {
//   color: #B01352;
// }

// .anagrafica.mat-form-field, .percorso.mat-form-field {
//   width: 100%;
// }



// .mezzi_container .mat-form-field {
//   width: 100%;
// }


// .price_container .mat-form-field {
//   width: 500px !important;
// }


// .datepickers .mat-form-field-flex {
//   align-items: center;
// }

// .datepickers .mat-form-field-prefix, .mat-form-field-suffix {
//   margin-right: 10px;
// }


// .posti_max_container .mat-form-field-infix {
//   border-top-width: 0;
// }


// .azienda .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
//   right: 20px !important;
// }


// .mat-form-field-label-wrapper, .mat-form-field.mat-form-field-invalid .mat-form-field-label {
//   font-size: 14px;
// }


// #anagrafica_container .mat-form-field {
//   line-height: 1 !important;
//   margin-bottom: 30px;
// }


// #anagrafica_container .mat-form-field-appearance-legacy .mat-form-field-infix {
//   height: 40px;
// }

// #anagrafica_container .mat-form-field-flex, #percorso_container .mat-form-field-flex {
//   background: #F2F2F2 !important;
//   height: 40px !important;
//   box-shadow: none;
// }

// .mezzi_container .mat-form-field-flex {
//   height: 40px !important;
//   box-shadow: none;
// }

// .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after, .mat-input-element:disabled, input.mat-input-element {
//   font-size: 13px;
// }

// #anagrafica_container .mat-form-field-appearance-legacy.mat-form-ripple {
//   top: 0 !important;
// }

// #anagrafica_container .mat-form-field-wrapper {
//   padding-bottom: 0;
// }



// #anagrafica_container .mat-form-field-appearance-legacy .mat-form-field-infix, #stepper_container .mat-form-field-appearance-legacy .mat-form-field-infix {
//   padding: 13px 20px;
// }

// .mezzi_container .mat-form-field, #percorso_container .mat-form-field, #modal_white .mat-form-field {
//   margin-bottom: 20px;
// }

// .mezzi_container .mat-form-field-appearance-legacy .mat-form-field-wrapper, #percorso_container .mat-form-field-appearance-legacy .mat-form-field-wrapper, .stepper_container .mat-form-field-appearance-legacy .mat-form-field-wrapper, #modal_white .mat-form-field-appearance-legacy .mat-form-field-wrapper{
//   padding-bottom: 0;
// }



// #percorso_container .mat-form-field-appearance-legacy .mat-form-field-infix, .mezzi_container .mat-form-field-appearance-legacy .mat-form-field-infix, .stepper_container .mat-form-field-appearance-legacy .mat-form-field-infix {
//   padding: .5em 1.5em .5em 1.5em !important;
// }

// #percorso_container .mat-form-field-label, .mezzi_container .mat-form-field-label, #anagrafica_container .mat-form-field-label, .mezzi_container .mat-form-field-label, .stepper_container .mat-form-field-label {
//   margin-top: 8px !important;
// }


// .stepper_container .mat-form-field-label-wrapper {
//   top: -1.3em !important;
// }


// .percorso-card .mat-form-field-flex {
//   background: #F2F2F2;
//   box-shadow: none;
// }


// #percorso_container .mat-form-field-appearance-legacy .mat-form-field-infix {
//   bottom: 11px;
// }

// #percorso_container .mat-form-field-suffix {
//   padding-top:10px;
// }

// .login-container .mat-form-field-prefix {
//   top:3px;
// }



