
.mat-select-value{
  font-size: 15px !important;
}

.select .mat-select-value span{
  color: #ffffff !important;
  font-size: 15px;
}


.select .mat-select-trigger .mat-select-arrow-wrapper div{
  color:#ffffff !important;
}

.mat-select.white{
  color:white;
  .mat-select-value{
    color:white;
    span{
      color:white;
    }
  }

  .mat-select-arrow-wrapper{
    color:white;
    .mat-select-arrow{
      color: white;
    }
  }

  .mat-select-value-text{
    color:white !important;
  }
}


.mat-select-panel-wrap{
  @apply bg-white;
}
