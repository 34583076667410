mat-paginator{
  //position: relative;
  display: inline !important;
  width: 65%;
  right: 0;
  bottom: 0;
  // background-color: black !important;
  color: white;
  text-align: right;
  .mat-paginator-container {

    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    max-height: 50px !important;
    padding: 0 8px;
    flex-wrap: wrap-reverse;
    .mat-select-value{
      color: rgba(255, 255, 255, 0.4);
    }
  }
  .mat-paginator-page-size-select{
    background-color: transparent;
  }
  .mat-paginator-page-size-select,
  .mat-select-value,
  .mat-paginator-range-actions{
    padding:0;
    margin: 0;
    color: #30373A;
    // color: rgba(255, 255, 255, 0.4);
    button{
      // border: 1px solid #30373A;
      height: 28px;
      width:28px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
    }
    button.mat-button-disabled{
      border:none;
    }
  }
  .mat-form-field-underline{
    display: none ;
  }
  .mat-form-field-wrapper{
    padding: 0 !important;
  }
  .mat-form-field-infix{
    display: flex;
    justify-content: flex-end;
  }
  .mat-select{
    background-color: transparent;
    color: white !important;
    border: 2px solid #30373A;
    padding: 5px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    height: 28px;
    width: 70px !important;
  }
  .mat-select-arrow{
    color: #30373A;
    margin-top: 3px;
  }
  .mat-select .mat-select-value span {
    font-size: 12px;
    font-weight: 500;
  }

  .mat-select-value-text{
    margin-left: 15px;
  }

  .mat-paginator-range-label{
    font-weight: 500;
  }
  .mat-paginator-page-size-label{
    display: none;
  }
  .mat-button-wrapper{
    margin:0 !important;
  }
}

.mat-paginator-icon{
  border: 2px solid #30373A;
  border-radius: 50%;
  color: #30373A !important;
  margin-bottom: 3px;
}


/* mat paginator v15 */
.mat-mdc-paginator{
  @apply inline text-dark;
  //display: inline !important;
  //width: 65%;
  right: 0;
  bottom: 0;

  //color: white !important;
  text-align: right;


  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {




      @apply flex justify-end items-baseline;

      max-height: 50px !important;
      padding: 0 8px;
      flex-wrap: wrap-reverse;




      //
      //    .mat-mdc-paginator-page-size {
      //      .mat-mdc-paginator-page-size-label {
      //        background-color: transparent;
      //        display: none;
      //      }
      //
      //      .mat-mdc-form-field.mat-mdc-paginator-page-size-select {
      //        .mat-mdc-text-field-wrapper {
      //          padding-top: 4px;
      //          padding-bottom: 4px;
      //
      //          .mat-mdc-form-field-flex {
      //            .mat-mdc-form-field-infix {
                      .mat-mdc-select {
                        background-color: transparent;
                        color: white !important;
                        border: 2px solid #30373A;
                        padding: 5px;
                        border-radius: 20px;
                        text-align: center;
                        font-size: 14px;
                        height: 100%;
                        width: 100%;
                        // width: 70px !important;


                        .mat-mdc-select-trigger {
                          padding-left: 2px;
                          padding-right: 4px;

                          .mat-mdc-select-value {
                            color: rgba(255, 255, 255, 0.4);
                          }


                                            .mat-mdc-select-arrow {
                                              @apply text-dark;
                                            }
                        }
                      }
      //            }
      //
                  .mdc-notched-outline__leading ,
                  .mdc-notched-outline__notch,
                  .mdc-notched-outline__trailing{
                    border: none;
                  }
      //          }
      //        }
      //      }
      //    }
      //
      //    .mat-mdc-paginator-range-actions {
      //      padding: 0;
      //      margin: 0;
      //      color: rgba(255, 255, 255, 0.4);
      //      align-items: center;
      //
      //      .mat-mdc-paginator-range-label {
      //        // margin: 0;
      //        font-size: 12px;
      //      }
      //
      //      .mat-mdc-icon-button,
      //      .mat-mdc-paginator-navigation-first,
      //      .mat-mdc-paginator-navigation-previous,
      //      .mat-mdc-paginator-navigation-next,
      //      .mat-mdc-paginator-navigation-last {
      //        &[disabled] {
      //          //display: none;
      //          border: 1px solid rgba(255, 255, 255, 0.1);
      //
      //          .mat-mdc-paginator-icon {
      //            fill: rgba(255, 255, 255, 0.1);
      //          }
      //
      //        }
      //
      //        background-color: transparent;
      //        border: 1px solid rgba(255, 255, 255, 0.4);
      //        height: 28px;
      //        width: 28px;
      //        font-size: 10px;
      //        display: flex;
      //        align-items: center;
      //        justify-content: center;
      //        margin-right: 0.5rem;
      //        padding: 0 !important;
      //
      //        .mat-mdc-button-wrapper {
      //          color: rgba(255, 255, 255, 0.4);
      //        }
      //
      //        .mat-mdc-icon-button svg, .mat-mdc-icon-button img {
      //          width: 28px !important;
      //          color: rgba(255, 255, 255, 0.4);
      //          fill: white;
      //        }
      //
      //        .mat-mdc-paginator-icon {
      //          fill: rgba(255, 255, 255, 0.4);
      //        }
      //
      //
      //      }
      //
      //      button.mat-mdc-button-disabled {
      //        border: none;
      //      }
      //    }
      //  }


    }

  }






  //.mat-paginator-page-size-select{
  //  background-color: transparent;
  //}
  //.mat-paginator-page-size-select,
  //.mat-select-value,
  //.mat-paginator-range-actions{
  //  padding:0;
  //  margin: 0;
  //  color: #30373A;
  //  // color: rgba(255, 255, 255, 0.4);
  //  button{
  //    // border: 1px solid #30373A;
  //    height: 28px;
  //    width:28px;
  //    font-size: 14px;
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //    margin-right: 0.5rem;
  //  }
  //  button.mat-button-disabled{
  //    border:none;
  //  }
  //}
  //.mat-form-field-underline{
  //  display: none ;
  //}
  //.mat-form-field-wrapper{
  //  padding: 0 !important;
  //}
  //.mat-form-field-infix{
  //  display: flex;
  //  justify-content: flex-end;
  //}


  .mat-mdc-select .mat-mdc-select-value span {
    font-size: 12px;
    font-weight: 500;
  }
  //
  //.mat-select-value-text{
  //  margin-left: 15px;
  //}
  //
  .mat-mdc-paginator-range-label{

    font-weight: 500;
  }
  .mat-mdc-paginator-page-size-label{
    display: none;
  }
  //.mat-button-wrapper{
  //  margin:0 !important;
  //}
}
