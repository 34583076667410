.stepper_container {
  overflow-y: hidden;
  height: 100%;
  // height: calc(100vh - 90px - 60px) !important;
}
.mat-stepper-horizontal {
  &.stepperfixedheight-horizontal {
    @apply bg-transparent h-full;
    > .mat-horizontal-stepper-wrapper {
      background-color: transparent;
      overflow-y: hidden;
      height: 100%;
      //position: relative;
      display: grid;
      grid-template-areas:
        'header'
        'content';
      grid-template-rows: auto 1fr;
      //margin-bottom: 10rem;
      box-sizing: border-box;
      // height: calc(100vh - 90px - 60px) !important;
      //border: 5px solid rebeccapurple;
      .mat-horizontal-stepper-header-container {
        grid-area: header;
        //background-color: pink;
        height: 72px;
        .mat-horizontal-stepper-header {
          //background-color: red;

          .mat-step-header-label {
            color: #fff;
          }
        }

        .mat-step-icon {
          //background-color: yellow;

          &.mat-step-icon-selected {
            @apply bg-redMovesion border-redMovesion;

            span {
              color: white !important;
            }
          }
        }
      }

      .mat-horizontal-content-container {
        grid-area: content;
        //background-color: brown;
        position: relative;
        overflow-y: hidden;
        display: block;
        padding: 0px;

        .mat-horizontal-stepper-content {
          display: grid;
          grid-template-areas:
            'content'
            'footer';
          grid-template-rows: 1fr auto;
          width: 100%;

          overflow-y: hidden;
          //background-color: #2BD402;
          .stepper-content {
            grid-area: content;

            min-height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
          }
          .stepper-footer {
            grid-area: footer;
            width: 100%;

            button {
              font-size: 16px;
              height: 40px;
              min-width: 150px;
              line-height: 1;

              border-radius: 50px;

              &.indietro {
                background-color: #bbbbbb;
                border: none;
                color: white;
              }

              &.active {
                @apply bg-redMovesion;
              }
            }
          }
        }
      }
    }
  }
}
.mat-horizontal-stepper-content {
  height: 100%;
}

//.mat-step-icon-selected {
//  background-color: #B01352 !important;
//  border: 2px solid #B01352 !important;
//}
//
//.mat-step-icon-selected span {
//  color: white !important;
//}
